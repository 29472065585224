<script lang="ts">
  import helixSdk from '../lib/helixTracking';

  helixSdk.trackViewEvent({
    product: {
      viewId: '404',
      viewType: 'fel',
    },
  });
</script>

<h1>Oops, något verkar ha gått fel!</h1>

<div class="svt-newsletter_content-text">
  <p class="svt-newsletter_content-text_paragraph">Vänligen klick <a href="/">här</a> för att försöka igen .</p>
</div>
