<script lang="ts">
  import helixSdk from '../lib/helixTracking';

  helixSdk.trackViewEvent({
    product: {
      viewId: 'villkor',
    },
  });
</script>

<h1>Villkor</h1>

<div class="svt-newsletter_content-text">
  <p class="svt-newsletter_content-text_paragraph">
    SVT behandlar uppgifter om din e-postadress för att kunna skicka nyhetsbrev till dig och personalisera din upplevelse av SVT. Dina
    personuppgifter lämnas inte ut till någon annan och alla uppgifter stannar inom EU/EES.
  </p>
  <p class="svt-newsletter_content-text_paragraph">
    All personuppgiftsbehandling sker inom SVT:s publicistiska verksamhet. Det innebär att endast vissa bestämmelser i
    personuppgiftslagstiftningen gäller för behandlingen, t.ex. reglerna som rör säkerhet för personuppgifter. Vi gör alltid vårt yttersta
    för att skydda de personuppgifter vi behandlar genom adekvata säkerhetsåtgärder.
  </p>
  <p class="svt-newsletter_content-text_paragraph">
    Har du frågor om dina personuppgifter kontakta oss på <a href="mailto: dataskyddsforordningen@svt.se">dataskyddsforordningen@svt.se</a>.
  </p>
</div>
