<script lang="ts">
  import helixSdk from '../lib/helixTracking';

  helixSdk.trackViewEvent({
    product: {
      viewId: 'tack',
    },
  });
</script>

<div class="svt-newsletter_content">
  <h1>
    <img class="svt-newsletter_logo" src="/images/svtlogo-black_v3.png" alt="SVT logotyp" />
    Nyhetsbrev
  </h1>

  <p>Tack! Din prenumeration är nu verifierad och du är tillagd på vår utskickslista. Vi hörs snart!</p>
</div>
