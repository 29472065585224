<footer class="svt-newsletter_footer">
  <nav class="svt-newsletter_footer-nav">
      <div>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svtplay.se" data-reactid="706">SVT Play</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/barnkanalen/">SVT Barn</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/barnkanalen/barnplay/bolibompa-drakens-tradgard/">Bolibompa</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/nyheter/">SVT Nyheter</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/sport/">SVT Sport</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.oppetarkiv.se">Öppet arkiv</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/vader/" data-reactid="718">Väder</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/recept">Recept</a></span>
      </div>
      <div>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svtplay.se/kanaler">TV-tablå</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svtplay.se/program">Program A-Ö</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/svttext/web/pages/100.html">Text-tv</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/omoss">Om SVT</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/aboutus">About SVT</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/svt-bloggen/">SVT-bloggen</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/kontakt/cookies-och-personuppgifter">Om Cookies</a></span>
          <span class="svt-newsletter_footer-destination-item"><a class="svt-newsletter_footer-destination-link" href="//www.svt.se/omoss/kontakt">Hjälp & kontakt</a></span>
          <figure class="svt-newsletter_footer-logo"><a href="//www.svt.se" class="svt-newsletter_footer-logo_link"><img class="svt-newsletter_footer-logo_img" src="/images/svtlogo-white_v3.png" alt="SVT.se logotyp"></a>
              <figcaption class="svt-newsletter_footer-logo_text" data-reactid="741">© Sveriges Television AB</figcaption>
          </figure>
      </div>
  </nav>
</footer>
