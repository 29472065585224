const API_BASE_URL = '__API_URL__';
import { navigate } from 'svelte-routing';
export const getNewsletters = async () => {
    const res = await fetch(`${API_BASE_URL}/`);
    return await res.json();
};
export async function registerForNewsletter(listIds, email, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: 'POST',
            body: JSON.stringify({ listIds, email, token }),
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
            },
        });
        if (response.status === 500) {
            navigate('/error', { replace: true });
        }
        return true;
    }
    catch (err) {
        return false;
    }
}
