<script lang="ts">
  import { Router, Route } from 'svelte-routing';
  import { onMount } from 'svelte';
  import svtlibCookie from '@svt/cookie';

  import NewsLetterList from './pages/NewsLetterList.svelte';
  import Header from './components/Header.svelte';
  import Footer from './components/Footer.svelte';
  import Terms from './pages/Terms.svelte';
  import Error from './pages/Error.svelte';
  import Unsubscribe from './pages/Unsubscribe.svelte';
  import Verified from './pages/Verified.svelte';
  import Registered from './pages/Registered.svelte';

  export let url = '';
  onMount(async () => {
    svtlibCookie('CookieInformationRoot');
  });
</script>

<div class="svt-newsletter_flex-container">
  <Router {url}>
    <main class="svt-newsletter_content">
      <Route path="/" component={NewsLetterList} />
      <Route path="/villkor" component={Terms} />
      <Route path="/avregistrering" component={Unsubscribe} />
      <Route path="/verifierad" component={Verified} />
      <Route path="/error" component={Error} />
      <Route path="/registrering" component={Registered} />
    </main>
  </Router>

  <Footer />
</div>
