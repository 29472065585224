import createDataSdk from '@svt/data-web-sdk';
const sdk = createDataSdk({
    product: {
        name: 'svt-nyhetsbrev',
        family: 'övrigt',
        version: '1',
    },
    config: {
        env: 'prod',
        useReadOnlyCookieHandler: true,
    },
});
export default sdk;
