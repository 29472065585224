<script lang="ts">
  import { onMount } from 'svelte';
  import { getNewsletters, registerForNewsletter } from '../lib/api';
  import * as yup from 'yup';
  import Tooltip from '../components/Tooltip.svelte';
  import helixSdk from '../lib/helixTracking';
  const RECAPTCHA_KEY = '__RECAPTCHA_KEY__';

  let termsChecked = false;
  let ageChecked = false;
  let validEmail = false;
  let email = null;
  let registered = false;
  let tooltip = '';
  let validInput = false;
  let registering = false;

  interface Newsletter {
    ListID: string;
    Name: string;
    checked?: boolean;
  }

  interface NewsletterCategory {
    title: string;
    ids: Newsletter[];
  }

  let data: NewsletterCategory[] = [];

  function validateEmail() {
    validEmail = yup.string().email().isValidSync(email);
    revalidate();
  }

  function register(e) {
    //ts ignore-line
    registering = true;
    grecaptcha.ready(async () => {
      // eslint-disable-line
      const token = await grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' }); // eslint-disable-line
      const listIds = findCheckedLists();

      registered = await registerForNewsletter(listIds, email || '', token);
      registering = false;
      helixSdk.trackClickEvent({
        name: 'nyhetsbrev-subscribe',
        eventValues: {
          listIds: listIds,
        },
      });

      window.scrollTo(0, 0);
    });
  }

  function findCheckedLists(): string[] {
    const idList: string[] = [];

    return data.reduce((acc, curr) => {
      const ids = curr.ids.filter((id) => id.checked);
      if (ids.length > 0) {
        acc.push(...ids.map((id) => id.ListID));
      }
      return acc;
    }, idList);
  }

  function revalidate() {
    validInput = email !== null && validEmail && ageChecked && termsChecked && findCheckedLists().length > 0;
    tooltip = 'Du behöver göra följande steg för att kunna prenumera:';
    if (!email) {
      tooltip += '\n- Fyll i din email';
    } else if (!validEmail) {
      tooltip += '\n- Din email verkar inte vara korrekt, var god kontrollera den igen.';
    }
    if (!termsChecked) {
      tooltip += '\n- Du behöver godkänna vilkoren för nyhetsbreven i kryssrutan ovan.';
    }
    if (!ageChecked) {
      tooltip += '\n- Du behöver intyga att du är över 13år i kryssrutan ovan.';
    }
    if (findCheckedLists().length === 0) {
      tooltip += '\n- Du behöver välja minst ett nyhetsbrev att prenumerera på.';
    }
    return tooltip;
  }

  onMount(async () => {
    helixSdk.trackViewEvent({
      product: {
        viewId: 'start',
      },
    });

    revalidate();
    const list = await getNewsletters();
    data = list.map((l) => ({
      title: l.title,
      ids: l.ids.map((id) => ({ ...id, checked: false })),
    }));
  });
</script>

<svelte:head>
  <script src="https://www.google.com/recaptcha/api.js?render=6LeZUJ0mAAAAAGofuB32K53y5hqHisg_84f6dQ1Y" async defer></script>
</svelte:head>

<h1>
  <img class="svt-newsletter_logo" src="/images/svtlogo-black_v3.png" alt="SVT logotyp" />
  Nyhetsbrev
</h1>

{#if !registered}
  <div class="svt-newsletter_content-text">
    <p>
      Här kan du prenumerera på SVT:s nyhetsbrev. Få veckouppdateringar med tips på nya serier, dokumentärer, middagstips och annat
      spännande SVT-innehåll!
    </p>
  </div>

  <form novalidate class="svt-newsletter_form" id="subscribe" on:submit|preventDefault={register} autocomplete="off" data-lpignore="true">
    <div class="svt-newsletter_form-group">
      {#each data as list}
        <h2 class="svt-newsletter_form-header">{list.title}</h2>
        {#each list.ids as id, i}
          <div class="svt-newsletter_form-checkbox-container">
            <input
              tabindex="0"
              id="list:{id.ListID}"
              name="list:{id.ListID}"
              type="checkbox"
              bind:checked={id.checked}
              on:change={revalidate}
              on:keydown={(e) => {
                if (e.code === 'Enter') {
                  (id.checked = !id.checked), revalidate();
                }
              }}
            />
            <label class="svt-newsletter_form-checkbox-label" for="list:{id.ListID}">{id.Name}</label>
          </div>
        {/each}
      {/each}
    </div>

    <div class="svt-newsletter_form-group">
      <p>Lilla Aktuellt skolas nyhetsbrev hittar du bland <a href="https://urplay.se/nyhetsbrev">UR:s Nyhetsbrev</a>.</p>
    </div>

    <div class="svt-newsletter_form-group">
      <label class="svt-newsletter_form-label" for="email">E-postadress</label>
      <input
        type="text"
        class="svt-newsletter_form-text-input"
        id="email"
        name="email"
        placeholder="E-post"
        autocomplete="off"
        bind:value={email}
        on:input={validateEmail}
      />
    </div>

    <div class="svt-newsletter_legals svt-newsletter_form-group">
      <label class="svt-newsletter_form-label svt-newsletter_form-label--half">
        Jag godkänner <a href="/villkor" rel="noopener" target="_blank">villkoren</a>
        <input
          id="terms"
          name="terms"
          type="checkbox"
          bind:checked={termsChecked}
          on:change={revalidate}
          on:keydown={(e) => {
            if (e.code === 'Enter') {
              (termsChecked = !termsChecked), revalidate();
            }
          }}
        />
      </label>
      <label class="svt-newsletter_form-label svt-newsletter_form-label--half">
        Jag är över 13 år
        <input
          id="age"
          name="age"
          type="checkbox"
          bind:checked={ageChecked}
          on:change={revalidate}
          on:keydown={(e) => {
            if (e.code === 'Enter') {
              (ageChecked = !ageChecked), revalidate();
            }
          }}
        />
      </label>
    </div>

    <div class="svt-newsletter_form-group" style="display:flex">
      <Tooltip title={tooltip} enabled={!validInput}>
        <button tabindex="0" type="submit" disabled={!validInput || registering} class="svt-newsletter_form-button btn btn-success"
          >Prenumerera</button
        >
      </Tooltip>

      {#if registering}
        <div class="loader" style="display: flex; justify-content:center; align-items:center">
          <img src="/images/puff.svg" style="display: block;width:30px;height:30px;margin-right:10px" alt="" />
        </div>
      {/if}
    </div>

    <div class="svt-newsletter_form-group">
      <p style="margin-top: 1em;">Behöver du hjälp gå till: <a href="https://svt.se/kontakt">svt.se/kontakt</a></p>
    </div>
  </form>
{:else}
  <div class="svt-newsletter_success">
    <p>Tack! För att du ska få våra utskick behöver du verifiera din epost, det gör du via mailet vi precis skickade till dig.</p>
  </div>
{/if}

<style>
  .svt-newsletter_form {
    display: flex;
    flex-direction: column;
  }

  .svt-newsletter_form-button:disabled {
    background: #b5b5b5;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader img {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
</style>
